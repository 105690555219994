import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from './consensusEstimatesSlice';
import Loader from '../../../components/Loader';

import MarketSelectDropdown from '../../../components/MarketSelectDropdown';
import NumberOfRatings from './NumberOfRatings';
import ConsensusRecommendationChart from './ConsensusRecommendationChart';
import ConsensusEstimatesTable from '../../../components/ConsensusEstimatesTable';
import Notes from './Notes';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';

const StockInfoPopup = createLoadableComponent(() => import('../../../components/StockInfoPopup'));

const { CancelToken } = axios;
function setUrlParam(key, value) {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState({ [key]: value }, document.title, url);
}

function removeUrlParam(key) {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.replaceState({ [key]: null }, document.title, url);
}

function ConsensusEstimatesMain({
  market,
  minRatings,
  availableMarkets,
  minConsensusEstimatesRatings,
  estimatesRankingTypes,
}) {
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const data = useSelector((state) => state.marketsConsensusEstimates.data);
  const completed = useSelector((state) => state.marketsConsensusEstimates.completed);
  const error = useSelector((state) => state.marketsConsensusEstimates.err);
  const loading = useSelector((state) => state.marketsConsensusEstimates.loading);
  const defaultMinRatings = minConsensusEstimatesRatings[market]
    ? minConsensusEstimatesRatings[market][2]
    : 3;
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market || defaultMarket);
  const [selectedMinRatings, setSelectedMinRatings] = useState(minRatings || defaultMinRatings);

  if (!isSSR) {
    window.onpopstate = (event) => {
      if (event.state) {
        setSelectedMarket(event.state.market || null);
        setSelectedMinRatings(event.state.min_ratings || null);
      }
    };
  }

  const handleOnChangeMinRatings = (event) => {
    if (!event) {
      return;
    }

    const newMinRatings = parseInt(event.target.textContent);
    if (newMinRatings === selectedMinRatings) {
      return;
    }

    if (!isSSR) {
      setUrlParam('min_ratings', newMinRatings);
    }
    setSelectedMinRatings(newMinRatings);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(
      fetchData({
        market: selectedMarket,
        minRatings: selectedMinRatings,
        cancelToken: source.token,
      }),
    );
    return () => {};
  }, [selectedMinRatings, selectedMarket]);

  // update selectedMarket state when selectedMarket redux has changed.
  useEffect(() => {
    if (defaultMarket == null || defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }
    setSelectedMarket(defaultMarket);
    if (!isSSR) {
      const url = new URL(window.location);
      url.pathname = `/${defaultMarket.toUpperCase()}/consensus_estimates`;
      window.history.pushState({}, document.title, url);

      removeUrlParam('min_ratings');
    }
    setSelectedMinRatings(minConsensusEstimatesRatings[defaultMarket][2]);
  }, [defaultMarket]);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Consensus Estimates</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <div className="g-mb-30">
        <MarketSelectDropdown
          market={selectedMarket}
          variant="light"
          validMarkets={availableMarkets}
          updateUrlOnClick
        />
      </div>
      <div className="g-mb-30">
        <NumberOfRatings
          minRatingByMarket={minConsensusEstimatesRatings[selectedMarket.toLowerCase()]}
          selectedMinRatings={selectedMinRatings}
          handleOnClick={handleOnChangeMinRatings}
        />
      </div>
      <Loader visible={loading} classes="g-height-800" />
      {data && data.estimates_chart_data && (
        <div className="g-mb-50">
          <h5 className="g-mb-30">
            Consensus Estimates of All Companies with At Least {selectedMinRatings} Ratings
          </h5>
          <ConsensusRecommendationChart data={data.estimates_chart_data} />
        </div>
      )}

      {data && Object.keys(data.estimates_table_data).length > 0 && (
        <div>
          {Object.keys(data.estimates_table_data).map((type) => {
            return (
              <div key={type} className="g-mb-50">
                <h5 className="g-mb-30">{estimatesRankingTypes[type]}</h5>
                <ConsensusEstimatesTable
                  data={data.estimates_table_data[type]}
                  showStockInfoPopUp
                />
              </div>
            );
          })}
        </div>
      )}
      <StockInfoPopup />
      <Notes />
    </>
  );
}

ConsensusEstimatesMain.propTypes = {
  market: PropTypes.string,
  minRatings: PropTypes.number,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
  minConsensusEstimatesRatings: PropTypes.oneOfType([PropTypes.object]),
  estimatesRankingTypes: PropTypes.oneOfType([PropTypes.object]),
};

export default ConsensusEstimatesMain;
