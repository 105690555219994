import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  setAddedCriterias,
  removeScreenerError,
  resetQueryData,
  resetCriteriasDropdownLabel,
} from './marketScreenerSlice';
import SelectedCriterias from './SelectedCriterias';

function AddCriterias({
  market,
  criteriaConfig,
  criteriaGroups,
  parentIndex,
  orCriteria,
  stockCategories,
  onModalOpen,
  onModalClose,
}) {
  const dispatch = useDispatch();

  const addedCriterias = useSelector((state) => state.marketScreener.addedCriterias);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState();
  const [criterias, setCriterias] = useState({});
  const [collapse, setCollapse] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    setCriterias({});
    if (onModalOpen) {
      onModalOpen();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const cleanAllAddedCriterias = () => {
    dispatch(removeScreenerError());
    dispatch(resetQueryData());
    dispatch(resetCriteriasDropdownLabel());
    dispatch(
      setAddedCriterias({
        criteria: [],
      }),
    );
  };

  const handleCriteriasCheckboxChange = (event) => {
    if (!event) {
      return;
    }

    const { checked, value } = event.target;

    // deep clone the addedCriterias to allow modification
    const updatedCriterias = { ...criterias };
    if (checked) {
      updatedCriterias[value] = {
        active: true,
        title: criteriaConfig[value].display_name || criteriaConfig[value].title,
        tmpl: criteriaConfig[value].form_tmpl,
        form_val: criteriaConfig[value].form_val,
        market,
        templateValue: null,
        sub_criterias: [],
      };
    } else {
      delete updatedCriterias[value];
    }

    setCriterias(updatedCriterias);
  };

  const handleToggleDropdown = (key, show) => {
    if (show) {
      setShowDropdown(key);
    } else {
      setShowDropdown(null);
    }
  };

  const handleCloseDropdown = () => {
    setShowDropdown(null);
  };

  const handleRemoveSelectedCriteria = (key) => {
    const updatedCriterias = { ...criterias };
    delete updatedCriterias[key];
    setCriterias(updatedCriterias);
  };

  const handleApplyCriterias = () => {
    // Append new criterias to the existing array
    let updatedCriterias = [...addedCriterias];

    // convert new criterias to array
    const criteriasArray = Object.entries(criterias).map(([key, value]) => ({ [key]: value }));
    if (parentIndex !== undefined && orCriteria) {
      // add to or criteria (sub_criteria)
      if (updatedCriterias[parentIndex]) {
        const parentKey = Object.keys(updatedCriterias[parentIndex])[0];
        const parentCriteria = { ...updatedCriterias[parentIndex][parentKey] };

        parentCriteria.sub_criterias = [...parentCriteria.sub_criterias, ...criteriasArray];
        updatedCriterias[parentIndex] = { [parentKey]: parentCriteria };
      }
    } else {
      // add to and criteria
      updatedCriterias = [...updatedCriterias, ...criteriasArray];
    }

    dispatch(removeScreenerError());
    dispatch(
      setAddedCriterias({
        criteria: updatedCriterias,
      }),
    );
    setCriterias({});
    handleCloseModal();
  };

  const handleResetCriterias = () => {
    setCriterias({});
  };

  const handleToggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      {!orCriteria && (
        <div
          className={`${
            collapse ? 'collapsed' : ''
          } collapsable-container border g-border-1 g-border-radius-20 p-3 g-mb-30`}>
          <div className="d-flex justify-content-between">
            <h6 className="mb-0">Screener Criterias</h6>
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip> {collapse ? 'Expand all criterias' : 'Minimise all criterias'}</Tooltip>
              }>
              <Button
                variant="light"
                onClick={handleToggleCollapse}
                className={`${
                  collapse ? 'collapsed' : ''
                } btn-collapse g-min-width-auto g-width-25 g-height-25 g-border-1 p-1 g-border-radius-0 g-text-size-14 lh-sm`}
              />
            </OverlayTrigger>
          </div>

          <div className="collapsable-element g-mt-10 d-flex flex-column flex-lg-row flex-wrap gap-2 align-items-lg-center">
            <div className="d-flex gap-2 align-items-lg-center">
              <Button onClick={handleOpenModal}>
                Add Criteria
                <i className="fa-light fa-plus g-ml-5" />
              </Button>
              <Button variant="secondary" onClick={cleanAllAddedCriterias}>
                Reset
                <i className="fa-light fa-broom-wide g-ml-5" />
              </Button>
            </div>
            {Object.entries(addedCriterias).length === 0 ? (
              <div className="d-flex flex-grow-1 flex-row gap-2 justify-content-center align-items-center">
                <i className="fa-xl fa-light fa-octagon-exclamation text-gray-600" />
                <h5 className="mb-0">Criteria is empty</h5>
              </div>
            ) : (
              <SelectedCriterias
                market={market}
                criteriaConfig={criteriaConfig}
                criteriaGroups={criteriaGroups}
                stockCategories={stockCategories}
              />
            )}
          </div>
        </div>
      )}
      {orCriteria && (
        <Button variant="light" size="sm" onClick={handleOpenModal} className="add_or_criteria_btn">
          Add "OR" Criterias
          <i className="fa-light fa-plus g-ml-5" />
        </Button>
      )}
      <Modal
        show={showModal !== false}
        centered
        dialogClassName="g-text-size-14"
        backdrop="static"
        onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add {orCriteria && 'OR'} Criterias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* TODO: ADD SEARCH */}
          {Object.entries(criteriaGroups)
            .filter(([k, v]) => !v.market_specific || v.market_specific.includes(market))
            .map(([k, v]) => (
              <Dropdown
                key={k}
                className="g-mb-20"
                onToggle={(show) => handleToggleDropdown(k, show)}
                show={showDropdown === k}>
                <Dropdown.Toggle variant="light" className="w-100">
                  {v.name}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <div className="overflow-y-scroll styled-scrollbar g-max-height-270 g-mb-30">
                    {v.content.map((group) => {
                      if (group.criteria.length > 0) {
                        const checkboxes = group.criteria.map((criterion) => {
                          const { market_specific } = criteriaConfig[criterion];
                          if (
                            market_specific == null ||
                            market_specific == undefined ||
                            market_specific.includes(market)
                          ) {
                            return (
                              <li key={criterion} className="dropdown-item">
                                <Form.Label
                                  role="button"
                                  className="w-100 mb-0 d-flex gap-2 align-items-center">
                                  <Form.Check
                                    value={criterion}
                                    checked={criterias[criterion]?.checked}
                                    onChange={handleCriteriasCheckboxChange}
                                  />
                                  <span>{criteriaConfig[criterion].title}</span>
                                </Form.Label>
                              </li>
                            );
                          }
                        });

                        // Check if there's at least one checkbox，then only render h5
                        if (checkboxes.some(Boolean)) {
                          return (
                            <ul key={group.key} className="list-unstyled g-mb-10">
                              <li className="g-fw-500 g-text-size-16">{group.title}</li>
                              {checkboxes}
                            </ul>
                          );
                        }
                      }
                    })}
                  </div>
                  <div className="text-center">
                    <Button onClick={handleCloseDropdown}>OK</Button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ))}
          <div className="g-mb-35">
            {Object.entries(criterias).length > 0 && (
              <>
                <h6>Selected Criterias</h6>
                <div className="d-flex gap-2 flex-wrap">
                  {Object.entries(criterias).map(([key, val]) => {
                    return (
                      <span
                        key={key}
                        className="badge text-bg-secondary py-2 px-3 position-relative">
                        {val.title}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Remove</Tooltip>}>
                          <span
                            role="button"
                            onClick={() => handleRemoveSelectedCriteria(key)}
                            className="position-absolute"
                            style={{ top: '-5px', right: '-5px' }}>
                            <i
                              className="fa-duotone fa-circle-xmark fa-lg"
                              style={{
                                '--fa-primary-color': '#ffffff',
                                '--fa-secondary-color': '#dc3545',
                                '--fa-secondary-opacity': '1',
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className="d-flex gap-2 justify-content-center">
            <Button onClick={handleApplyCriterias}>Add Criterias</Button>
            <Button variant="secondary" onClick={handleResetCriterias}>
              Reset
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

AddCriterias.propTypes = {
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
  criteriaGroups: PropTypes.oneOfType([PropTypes.object]),
  parentIndex: PropTypes.number,
  orCriteria: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default AddCriterias;
